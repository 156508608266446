import {
    SET_AGENT,SET_AGENT_LIST

} from "./UserActions";

const initialState = {
    agent: {},
    totalAgentList: [],
    totalAgentMap: {}
};

const UserReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_AGENT:
            return {
                ...state,
                agent: action.agent
            };

        case SET_AGENT_LIST:
            let objectMap = {};
            if (action.agentList && action.agentList.length > 0) {
                action.agentList.forEach(agent => {
                    objectMap[agent._id] = agent;
                })
            }
            return {
                ...state,
                totalAgentList: action.agentList,
                totalAgentMap: objectMap
            };

        default:
            return state;
    }
};

/* Selectors */
export const getActiveAgentId = (state) => state.user.agent._id;
export const getActiveAgent = (state) => state.user.agent;
export const getTotalAgentMap = (state) => state.user.totalAgentMap;

export default UserReducer;
