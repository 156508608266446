import React, { Component } from 'react';
import NormalLink from './NormalLink';
import AccessControl from "../PermissionLocks/AccessControl";

const displayStyle = { display: 'block'};
const noDisplayStyle = { display: 'none'};

export class DropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    };

    componentDidMount () {
        let activeUrlList = this.props.list.filter(item => item.url === this.props.activeUrl);
        if (activeUrlList && activeUrlList.length > 0) {
            this.setState({
                isOpen: true
            });
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.activeUrl !== this.props.activeUrl) {
            let activeUrlList = this.props.list.filter(item => item.url === this.props.activeUrl);
            if (activeUrlList && activeUrlList.length > 0) {
                this.setState({
                    isOpen: true
                });
            }
        }
    };

    getBlockClass = () => {
        let text = 'hasChild';
        let activeUrlList = this.props.list.filter(item => item.url === this.props.activeUrl);
        if (activeUrlList && activeUrlList.length > 0) {
            text += 'open active';
        } else if (this.state.isOpen) {
            text += ' open';
        }
        return text;
    };

    toggleOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    render() {
        return (
            <li className={this.getBlockClass()} onClick={() => this.toggleOpen()}>
                <a href="#"><i className={this.props.icon} /><span>{this.props.name}</span></a>
                <ul className="acc-menu" style={this.state.isOpen ? displayStyle : noDisplayStyle}>
                    {this.props.list.map((item) => (
                        <AccessControl
                            userPermissions={this.props.userPermissions}
                            shouldHavePermissions={item.shouldHavePermissions ? item.shouldHavePermissions : []}
                        >
                            <NormalLink
                                key={item.url}
                                url={item.url}
                                name={item.name}
                                icon={item.icon}
                                activeUrl={window && window.location ? window.location.pathname : null}
                            />
                        </AccessControl>
                    ))}
                </ul>
            </li>
        );
    }
}

export default DropDown;

